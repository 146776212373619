import React from "react";
import styles from "./footer.module.scss";

const Three: React.FC = () => (
  <footer className={`${styles["section-footer"]}`}>
    <div className={`${styles["footer-container"]}`}>
        <a target="_blank" rel="noopener noreferrer" className={styles.linkedin} href="https://www.linkedin.com/in/bvst89">
            <img src="linkedin-icon-logo-black-and-white.png" alt="linkedin" />
        </a>
        <p>bjorn.thoresen@urso.no</p>
        <p>Samarbeider med:</p>
        <a href="https://yne.no" rel="noopener noreferrer" target="_blank">Yne</a>
        <br/>
        <a href="https://moddam.no" rel="noopener noreferrer" target="_blank">Moddam</a>
    </div>
  </footer>
);

export default Three;
