import React from 'react';
import Header from './section/header/header.module';
import Main from './section/main/main.module';
import Footer from './section/footer/footer.module';

function App() {
  return (
    <div className="App">
      <Header />
      {/* <Main /> */}
      <Footer />
    </div>
  );
}

export default App;
