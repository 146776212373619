import React from "react";
import styles from "./header.module.scss";

const Header: React.FC = () => (
  <div className={`${styles["section-one"]} section`}>
    <div>URSO</div>
    <div className={`${styles["main-text"]}`}>
      Lang erfaring med systemutvikling
    </div>
  </div>
);

export default Header;
